import {
  Participant,
  State as ParticipantState,
} from '@wix/ambassador-challenges-v1-participant/types';
import { Trigger, Reward } from '@wix/ambassador-challenges-v1-challenge/types';

function isRewardEarned(
  trigger: Trigger,
  stepsNumber: number,
  completedStepsNumber: number,
  isCompletedWithFullPerformance: boolean,
): boolean {
  switch (trigger) {
    case Trigger.ALL_STEPS_COMPLETED:
      return (
        isCompletedWithFullPerformance || stepsNumber === completedStepsNumber
      );
    case Trigger.JOINED_TO_CHALLENGE:
      return true;
    case Trigger.STEP_COMPLETED:
      return completedStepsNumber > 0;
    default:
      return false;
  }
}

export function getEarnedRewards(
  rewards?: Reward[],
  participant?: Participant,
): {
  rewards: Reward[];
} {
  const stepsSummary = participant?.stepsSummary;
  const { stepsNumber = 0, completedStepsNumber = 0 } = stepsSummary || {};

  const isProgramCompleted =
    participant?.transitions?.[0]?.state === ParticipantState.COMPLETED;
  const isCompletedWithFullPerformance =
    isProgramCompleted && participant.performance === 100; // this case is actual if owner finished program before end date

  if (!rewards.length) {
    return { rewards: [] };
  }

  const filteredRewards = rewards.filter(({ trigger }) =>
    isRewardEarned(
      trigger,
      stepsNumber,
      completedStepsNumber,
      isCompletedWithFullPerformance,
    ),
  );

  return {
    rewards: filteredRewards,
  };
}
